import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0,4];

export const dictionary = {
		"/": [~6],
		"/(app)/home": [7,[2]],
		"/login": [20],
		"/onboard": [21,[4]],
		"/onboard/done": [22,[4]],
		"/onboard/step-1": [23,[4]],
		"/onboard/step-2": [~24,[4]],
		"/onboard/step-3": [~25,[4]],
		"/onboard/step-4": [~26,[4]],
		"/(app)/order": [8,[2]],
		"/(app)/order/[shortId]": [9,[2]],
		"/(app)/order/[shortId]/confirmation": [10,[2]],
		"/reset-password": [27],
		"/reset-password/accepted": [28],
		"/set-password": [29,[5]],
		"/set-password/success": [30,[5]],
		"/(app)/settings": [11,[2,3]],
		"/(app)/settings/account": [12,[2,3]],
		"/(app)/settings/account/confirm-email-change": [13,[2,3]],
		"/(app)/settings/account/success": [14,[2,3]],
		"/(app)/settings/password": [15,[2,3]],
		"/(app)/settings/password/success": [16,[2,3]],
		"/(app)/settings/retail-sites": [17,[2,3]],
		"/(app)/settings/retail-sites/new": [~19,[2,3]],
		"/(app)/settings/retail-sites/[retailSiteId=uuidv7]": [~18,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';