import { Temporal } from "temporal-polyfill";
import { pattern, timestamp, v7 as uuid7, type EntropyOptions } from "ui7";
import type { Brand } from "valibot";

export type UuidV7 = string & Brand<"UUIDv7String">;

/**
 * Return true if the string passed as an argument represents a valid UUID v7.
 */
export function isValidUuidV7(s: string): s is UuidV7 {
	return !!s && s.length === 36 && pattern.test(s);
}

/** Compute and return the instant associated to a given UUID v7 */
export function instantFromUuidV7(uuid: string) {
	return Temporal.Instant.fromEpochMilliseconds(timestamp(uuid));
}

export function uuid7FromTemporal(
	temporal: { epochMilliseconds: number },
	entropy: EntropyOptions["entropy"] = 0,
) {
	return uuid7({
		entropy,
		time: temporal.epochMilliseconds,
	});
}
